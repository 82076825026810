import { ChangeDetectorRef, Component, OnInit, Input } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { ExtractDigitalAccountResponse, ExtractDigitalAccountService } from '@wlp/ui-bs-cashout';
import { UserInfoService } from '@wlp/ui-bs-login';
import { UserInfoProfile } from '@wlp/ui-bs-login/lib/dto/user-info-profile';
import * as moment from 'moment';
import { ModalCsvGenerateComponent } from 'src/app/views/themes/sentinel/modals/modal-csv-generate/modal-csv-generate.component';
import { ModalPdfGenerateComponent } from 'src/app/views/themes/sentinel/modals/modal-pdf-generate/modal-pdf-generate.component';
import { PopoverSaveOptionsComponent } from 'src/app/views/themes/sentinel/modals/popover-save-options/popover-save-options.component';
import { UserProfile } from 'src/app/core/dto/user-profile';
import { UiBsMyDataService } from '@wlp/ui-bs-my-data';
import { catchError, tap } from 'rxjs/operators';

import { ExtractDataFilterPipe } from '../extract-data-filter.pipe';
import { ExtractUtil } from '../extract-util';
import { ExtractUtilInterface } from '../extract-util.interface';
import { Storage } from '@ionic/storage';
import { log } from 'util';
import { SchedulingService } from '@wlp/ui-bs-scheduling'
import { of } from 'rxjs';
import { Router } from '@angular/router';


@Component({
  selector: 'ui-t-extract-digital-account',
  templateUrl: './extract-digital-account.page.html',
  styleUrls: ['./extract-digital-account.page.scss'],
  providers: [ExtractDataFilterPipe],
})
export class ExtractDigitalAccountPage extends ExtractUtil implements ExtractUtilInterface, OnInit {

  userProfile: UserInfoProfile;
  secundary: boolean
  public startDate: Date;
  public endDate: Date;
  public printPeriodPdf: any;
  public bankname: any;
  schedulingData = []

  @Input() dateInitial;
  @Input() dateEnd;
  @Input() fullPeriod;
  @Input() filterDate;

  constructor(
    public popoverController: PopoverController,
    public pipeFilter: ExtractDataFilterPipe,
    public detection: ChangeDetectorRef,
    public modalController: ModalController,
    private extractDigitalAccountService: ExtractDigitalAccountService,
    protected userInfoService: UserInfoService,
    private myDataService: UiBsMyDataService,
    private schedulingService: SchedulingService,
    private router: Router,
    private storage: Storage) {
    super(pipeFilter, detection, modalController);
  }

  ngOnInit(): void {
    this.loadUser();

    document.getElementById('expandableRow').addEventListener('click', function() {
      var content = document.getElementById('expandableContent');
      var arrow = document.getElementById('arrow');
    
      content.classList.toggle('expanded');
      arrow.classList.toggle('down-arrow');
    
      if (content.classList.contains('expanded')) {
        arrow.style.transform = 'rotate(180deg)';
      } else {
        arrow.style.transform = 'rotate(0deg)';
      }
    });
  }

  ionViewWillEnter() {
    this.listStatment = [];
    this.getDigitalStatement();
    this.checkViewBalanceSecundaryUser();
    this.getAllScheduling(
      this.userProfile.uuidAccount,
      moment(this.initialSchedulingDate).format('YYYY-MM-DD'),
      moment().add(7, 'days').format('YYYY-MM-DD'),
      25,
      1
    )
  }

  async checkViewBalanceSecundaryUser(){
    await this.storage.get('permissions')
    .then(res => {
      console.log(res)
      this.secundary = res[0] === '*' ? false : true
    })
  }

  loadUser() {
    try {
      const userDetails = this.userInfoService.getInfoProfile();
      userDetails.then(user => {
        this.userProfile = user;
        this.loadBankInfo(this.userProfile);
      });
    } catch (e) {
      console.log('Failed to load user details', e);
    }
  }

  public getDigitalStatement(initial?: Date) {
    if (initial === undefined) {
      initial = this.calInitialDate;
    }

    this.getAllScheduling(
      this.userProfile.uuidAccount,
      moment(this.initialSchedulingDate).format('YYYY-MM-DD'),
      moment(this.endSchedulingDate).format('YYYY-MM-DD'),
      25,
      1
    )

    this.extractDigitalAccountService
      .getStatement(this.userProfile.uuidWhiteLabel, this.userProfile.uuidAccount, initial, this.calEndDate)
      .subscribe(
        res => {
          this.listStatment = res;
          // atualiza o front da tabela
          this.setArrayForShow(true);
          this.calcPagination();
        },
        err => console.error('Erro', err));
  }

  public valueDisplayCreditOrDebit(extractDigitalAccount: ExtractDigitalAccountResponse): number {
    return this.extractDigitalAccountService.valueDisplayCreditOrDebit(extractDigitalAccount);
  }

  showIsBalance(typeStatement: string): boolean {
    return this.extractDigitalAccountService.showIsBalance(typeStatement);
  }

  showIsCreditOrDebit(typeStatement: string): boolean {
    return this.extractDigitalAccountService.showIsCreditOrDebit(typeStatement);
  }

  isCredit(typeStatement: string): boolean {
    return this.extractDigitalAccountService.isCredit(typeStatement);
  }

  /**
 * apresentacao da data na tabela
 * @param date
 */
  public getHourDate(date: any): string {
    try {
      return `${moment(this.formattedDate(date)).format('HH:mm:ss')}`;
    } catch (err) {
      return '';
    }
  }

  public formattedDate(date) {
    if (date && typeof date === 'object' && date.constructor === Array) {
      if (date.length >= 6) {
        return new Date(date[0], (date[1] - 1), date[2], date[3], date[4], date[5]);
      } else if (date.length === 5) {
        return new Date(date[0], (date[1] - 1), date[2], date[3], date[4]);
      }
      return new Date(date[0], date[1], date[2]);
    }

    return date;
    
  }

  
  public async openPDF() {
    if (this.filterDate === true){
      this.printPeriodPdf = `${this.dateInitial} à ${this.dateEnd}`;
    } else {
      if (this.fullPeriod === undefined) { 
        this.printPeriodPdf = 'Últimos 7 dias';
      }
      else {
        this.printPeriodPdf = `Últimos ${this.fullPeriod} dias`;
      }
    }

    const modal = await this.modalController.create({
      component: ModalPdfGenerateComponent,
      cssClass: 'modal-pdf-generate',
      componentProps: {
        listStatmentShow: this.listStatment,
        userProfile: this.userProfile,
        printPeriodPdfShow: this.printPeriodPdf,
        banknameShow: this.bankname
      },
    });
    return await modal.present();
  }

  public async openCSV() {
    const modal = await this.modalController.create({
      component: ModalCsvGenerateComponent,
      cssClass: 'modal-csv-generate',
      componentProps: {
        listStatmentShow: this.listStatment,
        userProfile: this.userProfile
      },
    });
    return await modal.present();
  }

  async presentPopover(ev: any) {
    const popover = await this.popoverController.create({
      component: PopoverSaveOptionsComponent,
      cssClass: 'popover-save-options',
      event: ev,
      translucent: true,
      showBackdrop: false,
      mode: 'md'
    });

    await popover.present();

    const { data } = await popover.onDidDismiss();
    data && await this.openModal(data);
  }

  async openModal(type: string) {
    const modal = {
      'pdf': () => this.openPDF(),
      'csv': () => this.openCSV()
    }
    return await modal[type]();
  }

  loadBankInfo(userProfile: UserProfile) {
    this.myDataService
      .getWhiteLabelData(userProfile.uuidWhiteLabel)
      .pipe(
        tap((data) => {
          this.bankname = data.fantasyName;
        })
      )
      .subscribe();
  }


  getAllScheduling(
    uuidAccountPayment: string,
    startExecutionDate: string,
    endExecutionDate: string,
    limit: number,
    page: number
  ) {
    this.schedulingService.getAllScheduling(
      uuidAccountPayment,
      startExecutionDate,
      endExecutionDate,
      limit,
      page
    )
      .pipe(
        tap(res => {

          this.schedulingData = res.data

        }), catchError(err => {
          console.log('Erro', err)
          return of(err)
        })
      ).subscribe();
  }

  translateStatus(status){
    if(status == 'TED_ON_US'){
      return "TED ONUS"
    } else
    if(status == 'BILLET') {
      return 'PAGAMENTO DE BOLETO'
    } else 
    if(status == 'RECHARGE'){
      return "RECARGA"
    } else {
      return status
    }
  }

  public dateControl(date: any): string {
    try {
      return `${moment(this.formattedDate(date)).format('DD/MM/YYYY')}`;
    } catch (err) {
      return '';
    }
  }

  valueClassChange(wasSuccess, wasExecuted) {
    if(wasExecuted == true && wasSuccess == false){
      return 'danger-value'
    } else {
      return 'ok-value'
    }
  }

  devolutionPix(item) {
    this.router.navigate(['/app/pix/devolution/'], { state: {item} });
  }

  contaionPix(searchText: string, text) {
    let lowerCaseSearchText = searchText.toLowerCase();

    let containsPix = lowerCaseSearchText.indexOf('pix') !== -1;
    let containsEstorno = lowerCaseSearchText.indexOf('estorno') !== -1;

    return containsPix && !containsEstorno;

  }

}
